<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW AFFILIATE</h4></v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              v-model="selection_data"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Generals', 'Rental']"
              @change="selected_category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              @change="selected_category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              v-if="!is_forbidden"
              outlined
              class="mx-2"
              v-model="selection"
              dense
              label="Selection"
              :items="['Branch', 'Regional', 'Territory']"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              v-model="branch_id"
              class="mx-2"
              dense
              outlined
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              @change="selected_branch"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              outlined
              class="mx-2"
              v-model="particulars_id"
              dense
              label="Particulars"
              :items="particulars_items"
              item-value="particulars"
              item-text="particulars"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3 v-if="selection_data === 'Rental'">
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="save_request" v-if="!saving_data"> Save
              Request
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table
            dense
            :headers="headers"
            :items="data_items"
            height="600"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.particulars.particulars }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            dense
            :headers="headers2"
            :items="data_items2"
            height="600"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.branch.branch_code }}
                </td>
                <td>
                  {{ item.particulars.particulars }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            dense
            :headers="headers3"
            :items="data_items3"
            height="600"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.branch.branch_code }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.due_date!=null?date_format(item.due_date).format('D')
                  +(date_format(item.due_date).format('D')==='1'?'st':
                  (date_format(item.due_date).format('D')==='2'?'nd':
                  (date_format(item.due_date).format('D')==='3'?'rd':'th'))):''
                  }}
                </td>
                <td>
                  {{ item.particulars.particulars }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td class="text-center">
                  <v-icon v-if="!can_edit" class="mr-2" color="info" @click="edit_details(item)">
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <v-icon v-if="!can_view" class="mr-2" color="warning"
                          @click="view_history(item)">
                    {{ icons.mdiHistory }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="can_view||can_edit"></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="can_edit" persistent max-width="40%">
      <v-card>
        <v-form ref="form" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">EDIT AFFILIATE DETAILS</h4>
            </v-toolbar-title>
          </v-toolbar>

          <v-layout row wrap class="align-center mx-2 mt-5">
            <v-flex xs12 md12>
              <v-text-field
                class="mx-2"
                v-model="name"
                dense
                label="Name"
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                class="mx-2"
                v-model="amount"
                dense
                label="Amount"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
              <v-text-field
                class="mx-2"
                v-model="due_date"
                dense
                label="Start Date"
                outlined
                type="date"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn class="w-full" color="primary" @click="edit_data" v-if="!saving_data"> Update
                Details
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="can_edit = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="can_view" persistent max-width="40%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">AFFILIATE HISTORY</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            dense
            :headers="headers4"
            :items="data_items4"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.affiliate.id }}
                </td>
                <td>
                  {{ item.affiliate.branch.branch_code }}
                </td>
                <td>
                  {{ item.affiliate.name }}
                </td>
                <td>
                  {{ item.due_date!=null?date_format(item.due_date).format('D')
                  +(date_format(item.due_date).format('D')==='1'?'st':
                  (date_format(item.due_date).format('D')==='2'?'nd':
                  (date_format(item.due_date).format('D')==='3'?'rd':'th'))):''
                  }}
                </td>
                <td>
                  {{ item.affiliate.particulars.particulars }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{
                  item.created_by != null
                  ? item.created_by.last_name + ', ' + item.created_by.first_name
                  : ''
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-btn color="error" @click="can_view = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountReactivate, mdiHandshake, mdiAccountGroup,
    mdiPencil,
    mdiHistory,
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',
      is_deleting: false,
      can_edit: false,

      is_forbidden: true,
      name: '',
      particulars_id: '',
      branch_id: '',
      selection: '',
      category_id: '',
      selection_data: '',
      amount: '',
      due_date: '',
      particulars_items: [],
      branch_items: [],
      category_items: [],

      data_items: [],
      data_items2: [],
      data_items3: [],
      data_items4: [],

      selected_affiliate_id: -1,

      tab: '',
      tabs: [
        {title: 'ALL GENERAL', icon: mdiAccountReactivate},
        {title: 'GENERALS', icon: mdiAccountGroup},
        {title: 'RENTALS', icon: mdiHandshake},
      ],
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'name', sortable: true},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
      ],
      headers2: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'name', sortable: true},
        {text: 'Branch', value: 'branch.branch_code', sortable: true},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'branch.branch_code', sortable: true},
        {text: 'Name', value: 'name', sortable: true},
        {text: 'Due Date', value: 'due_date', sortable: true},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'date_of_deposit', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
      ],
      headers4: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'branch.branch_code', sortable: true},
        {text: 'Name', value: 'name', sortable: true},
        {text: 'Due Date', value: 'due_date', sortable: true},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'date_of_deposit', sortable: false},
        {text: 'Created By', value: 'date_of_deposit', sortable: false},
      ],

      can_view: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountReactivate,
          mdiAccountGroup,
          mdiHandshake,
          mdiPencil,
          mdiHistory,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['initialize_new_particulars', 'request_particulars_data_for_affiliate']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('affiliate_history', ['list_of_affiliates_history']),
      ...mapActions('affiliatee', ['list_of_affiliates', 'register_affiliates', 'update_affiliates']),
      initialize_data() {
        this.list_of_affiliates()
          .then(response => {
            this.data_items = response.data[0].all_generals
            this.data_items2 = response.data[0].generals
            this.data_items3 = response.data[0].rentals
          })
          .catch(error => {
            console.log(error)
          })
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        this.is_forbidden = true
        // if (this.category_id === 4 || this.category_id === 3) {
        //   this.is_forbidden = false
        // }
        this.branch_id = ''
        if (this.category_id === -1) {
          this.branch_items.splice(0, 0, {
            id: -1,
            branch_code: 'All',
          })
        } else {
          this.branch_data({
            category_id: this.category_id,
            is_region: this.selection === 'Regional' ? 1 : 0,
            is_territory: this.selection === 'Territory' ? 1 : 0,
          })
            .then(response => {
              this.branch_items = response.data[0].active
              if (this.selection_data != 'Rental') {
                this.branch_items.splice(0, 0, {
                  id: -1,
                  branch_code: 'All',
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
        this.particulars_id = ''
        this.request_particulars_data_for_affiliate({
          category_id: this.category_id,
          selection_data: this.selection_data,
        })
          .then(response => {
            this.particulars_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_branch() {
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id === -1 ? '' : this.branch_id)
          data.append('particulars_id', this.particulars_id)
          data.append('category_id', this.category_id)
          data.append('employee_id_created', this.employee_id)
          data.append('amount', this.amount === '' ? 0 : this.amount)
          data.append('name', this.name.toUpperCase())
          data.append('is_bills', 0)
          this.register_affiliates(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      edit_details(value) {
        this.selected_affiliate_id = value.id
        this.can_edit = true
        this.name = value.name
        this.amount = value.amount
      },
      view_history(value) {
        this.selected_affiliate_id = value.id
        this.can_view = true
        this.list_of_affiliates_history({
          affiliate_general_id: value.id
        })
          .then(response => {
            this.data_items4 = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      edit_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.selected_affiliate_id)
          data.append('amount', this.amount === '' ? 0 : this.amount)
          data.append('name', this.name.toUpperCase())
          data.append('due_date', this.due_date)
          data.append('employee_id_created', this.employee_id)
          this.update_affiliates(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
